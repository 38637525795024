import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const LeftRimorchio = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
      </div>
      <div className="flex-col space-y-8">
        {/* Price Item */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              100
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const RightRimorchio = () => {
  return (
    <div className="sm:w-full md:w-full lg:w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="">
        <h2 className="text-4xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
          Per effettuare il passaggio di un rimorchio inferiore ai 3500kg sono necessari i seguenti documenti:
        </h2>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">
            Carta di circolazione
          </li>
          <li className="text-gray-700 text-xl">
            Documento d'identità acquirente
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (in caso di acquirente extracomunitario)
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftRimorchio, RightRimorchio };
