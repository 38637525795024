import React from "react";

import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LeftColumn = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
        <p className="text-center text-blue-accent-700"></p>
      </div>
      <div className="flex-col space-y-8">
        {/* First Price Item */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              80
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            con targa sospesa a nome dell'acquirente
          </p>
        </div>

        {/* Second Price Item */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              95
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            con acquisto di una targa nuova
          </p>
        </div>
      </div>
    </div>
  );
};

const RightColumn = () => {
  return (
    <div>
      <h2 className="text-4xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
        Per effettuare il passaggio di un ciclomotore sono necessari i seguenti
        documenti:
      </h2>
      <div className="">
        <h3 className="text-2xl mb-2 font-roboto font-bold tracking-tight text-gray-900">
          Se in regime di sospensione:
        </h3>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">
            Documento d'identità acquirente
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (se l'acquirente è extracomunitario)
          </li>
          <li className="text-gray-700 text-xl">
            Certificato sospensione ciclomotore
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <h3 className="text-2xl mb-2 font-roboto font-bold tracking-tight text-gray-900">
          Con sospensione non effettuata:
        </h3>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">Certificato di circolazione</li>
          <li className="text-gray-700 text-xl">
            Documento d'identità venditore
          </li>
          <li className="text-gray-700 text-xl">
            Documento d'identità acquirente
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (in caso di venditore/acquirente
            extracomunitario)
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftColumn as LeftCiclo, RightColumn as RightCiclo };
