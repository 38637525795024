import React from "react";

const LeftColumn = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
      </div>
      <div className="flex-col space-y-8">
        {/* First Price Item */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              Fisso 
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            per motocicli
          </p>
        </div>

        {/* Second Price Item */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              Variabile
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            Per autovetture, autocarri ed autocaravan, a seconda di potenza e residenza acquirente.
          </p>
        </div>
      </div>
    </div>
  );
};

const RightColumn = () => {
  return (
    <div className="sm:w-full md:w-full lg:w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="">
        <h2 className="text-4xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
          Per effettuare il passaggio di proprietà di un veicolo iscritto al PRA e alla motorizzazione civile sono necessari i seguenti documenti:
        </h2>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">
            Certificato di proprietà
          </li>
          <li className="text-gray-700 text-xl">
            Documento unico (DU) o carta di circolazione
          </li>
          <li className="text-gray-700 text-xl">
            Documento d'identità del venditore in corso di validità
          </li>
          <li className="text-gray-700 text-xl">
            Documento d'identità dell’acquirente in corso di validità
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (in caso di venditore/acquirente extracomunitario)
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftColumn as LeftAuto, RightColumn as RightAuto };
