import { faCar, faMotorcycle, faTruck } from '@fortawesome/free-solid-svg-icons';
export const passaggioProprieta = {
    list: [
        {
            id: "pass1",
            title: "Passaggio di proprietà ciclomotore",
            link: "/pratiche-auto/passaggio-di-proprieta/#ciclomotore",
            icon: faMotorcycle,
        },
        {
            id: "pass2",
            title: "Passaggio di proprietà autocarro, autovettura, autocaravan o motociclo",
            link: "/pratiche-auto/passaggio-di-proprieta/#auto",
            icon: faCar,
        },
        {
            id: "pass3",
            title: "Passaggio di proprietà rimorchio < 3,5 t",
            link: "/pratiche-auto/passaggio-di-proprieta/#rimorchio",            
            icon: faTruck,
        }
    ]
}