import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListFeatureB = ({ list }) => {
  return (
    <section className="text-gray-900 body-font ">
      <div className="container px-32 pt-4 mx-auto">
        <div className="flex flex-wrap lg:w-full sm:mx-auto sm:mb-2 -mx-2">
          {list.list.map((el) => (
            <div id={el.id} className="p-2 md:w-1/3 w-full">
              <div className="align-middlebg-gray-100 rounded flex p-4 h-full items-center content-center">
                <Link
                  href={el.link}
                  className="hover:bg-blue-100 border-blue-accent-700 w-full p-3 flex items-center justify-center rounded-lg hover:bg-gray-50"
                >
                  <FontAwesomeIcon
                    icon={el.icon}
                    className="pt-2 fa-solid flex-shrink-0 mx-4 h-10 w-10 text-blue-accent-700"
                  />
                  <span className="text-xl title-font font-medium mt-2">{el.title}</span>
                </Link>
              </div>
              
            </div>
          ))}
        </div>
      </div>
      <p className="border-b border-blue-900 mx-80 mb-4"></p>
    </section>
  );
};

ListFeatureB.defaultProps = {
  theme: 'indigo',
};

ListFeatureB.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ListFeatureB;
