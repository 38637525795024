import React from 'react';
import Layout from '../../components/layout/Layout';
import ListFeatureB from '../../components/ListFeatureB';
import ContentAM from '../../components/ContentAM';
import PageNavigator from "../../components/layout/PageNavigator";

import { LeftCiclo, RightCiclo } from "../../components/pratiche-auto/passaggio-proprieta/Ciclomotore"
import { LeftAuto, RightAuto } from '../../components/pratiche-auto/passaggio-proprieta/Auto';
import { LeftRimorchio, RightRimorchio } from '../../components/pratiche-auto/passaggio-proprieta/Rimorchio';

import ContentPratica from '../../components/ContentPratica';
import { passaggioProprieta } from '../../data/pratiche-auto/passaggio-di-proprieta';

const PassaggioDiProprieta = () => {
  return (
    <Layout>
      <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
      <ListFeatureB list={passaggioProprieta} />
      <ContentPratica id="ciclomotore" title="Passaggio di proprietà ciclomotore">
        <LeftCiclo />
        <RightCiclo />

      </ContentPratica>

      <ContentPratica id="auto" title="Passaggio di proprietà autocarro, autovettura, autocaravan o motociclo">
        <LeftAuto />
        <RightAuto />
      </ContentPratica>
      
      <ContentPratica id="rimorchio" title="Passaggio di proprietà rimorchio < 3,5 t">
        <LeftRimorchio />
        <RightRimorchio />
      </ContentPratica>
    </Layout>
  )
};

export default PassaggioDiProprieta;
